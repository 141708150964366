<template>
    <div class="table-responsive table-striped my-1" style="min-height: 200px">
        <table class="table">
            <thead class="table-light">
                <tr>
                    <th>SL No</th>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Mobile</th>
                    <th v-for="(column, index) in dynamicColumns" :key="index" class="text-right max-w-100">
                      {{column}}
                    </th>
                    <th>Total</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ index+1 }}
                    </td>
                    <td>{{item.serial_no}}</td>
                    <td>{{item.full_name}}</td>
                    <td>{{item.phone}}</td>
                    <td v-for="(column, index) in dynamicColumns" :key="index" class="text-right">
                      {{ commaFormat(item[index]) }}
                    </td>
                    <td>{{item.total}}</td>
                    <td>
                        <div class="action">
                            <button v-if="item.service_id" @click="onClickToGo(item)" class="btn btn-outline-primary btn-sm print-btn">
                                <i class="fas fa-eye"></i>
                            </button>
                        </div>
                    </td>

                </tr>
            </tbody>
        </table>
        <p v-if="!tableData.length" class="mt-5 text-center">No data</p>
    </div>
</template>

<script setup>

import {commaFormat} from "@/services/utils/global";
import { useRoute, useRouter } from "vue-router";
import useDate from "@/services/utils/day";

const props = defineProps({
  tableData: {
    type: Array
  },
  dynamicColumns: {
    type: Object
  }
})

const {
  firstDateOfMonth,
  lastDateOfMonth
} = useDate();

const router = useRouter();
const route = useRoute();

const onClickToGo = (item) => {
  router.push({
    name: 'doctor-incentive-detailed-report',
    params: {
      ... route.params,
      serviceId: item.service_id
    },
    query: {
      page: 1,
      start: firstDateOfMonth,
      end: lastDateOfMonth
    }
  })
}

</script>

<style scoped>
  .max-w-100 {
    max-width: 100px;
  }
</style>